
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonHtmlType } from "@/core/directive/type/common";
import { Upload } from "@element-plus/icons-vue";
import { ElUpload } from "element-plus";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "import-file-modal",
  props: {
    uploadUrl: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    clickToUpload: {
      type: String,
      default: "",
    },
    importUploadTip: {
      type: String,
      default: "",
    },
    importSuccess: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const importModalRef = ref<CommonHtmlType>(null);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
      "X-LANG": JwtService.getLang(),
    });

    const { showServerErrorMsg } = mixin();

    const formData = ref({
      uploadUrl: "",
      title: t("common.import"),
      clickToUpload: t("common.clickToUpload"),
      importUploadTip: t("common.importUploadTip"),
      importSuccess: t("common.importSuccess"),
    });

    const rules = ref({});

    const options = ref({});

    watch(
      () => props.uploadUrl,
      (newValue) => {
        formData.value.uploadUrl = newValue;
      }
    );

    watch(
      () => props.title,
      (newValue) => {
        formData.value.title = newValue;
      }
    );

    watch(
      () => props.clickToUpload,
      (newValue) => {
        formData.value.clickToUpload = newValue;
      }
    );

    watch(
      () => props.importUploadTip,
      (newValue) => {
        formData.value.importUploadTip = newValue;
      }
    );

    watch(
      () => props.importSuccess,
      (newValue) => {
        formData.value.importSuccess = newValue;
      }
    );

    const setErrorUploadFile = () => {
      uploadRef.value?.clearFiles();
    };

    const handleUploadError = () => {
      loading.value = false;
      setErrorUploadFile();
    };

    const handleUploading = () => {
      loading.value = true;
    };

    const handleUploadSuccess = (res, file) => {
      loading.value = false;
      if (res.code == 0) {
        Swal.fire({
          text: formData.value.importSuccess,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          resetForm();
          emit("update-list");
          hideModal(importModalRef.value);
        });
      } else {
        showServerErrorMsg(res);
        setErrorUploadFile();
      }
    };

    const handleDiscard = () => {
      hideModal(importModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      uploadRef.value?.clearFiles();
    };

    return {
      t,
      props,
      loading,
      formData,
      importModalRef,
      rules,
      options,
      uploadRef,
      handleDiscard,
      resetForm,
      handleUploadError,
      handleUploading,
      handleUploadSuccess,
      uploadHeaders,
      Upload,
    };
  },
});
