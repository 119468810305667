import { NumberOrString } from "../type/common";
import { InboundDamagedStatus } from "../type/inbound";

/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-13 15:06:30
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-01-17 13:52:09
 */
export const inboundInfoData: InboundInfo = {
  id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  created_uid: 0,
  updated_uid: 0,
  deleted_uid: 0,
  status: 0,
  batch_number: "",
  shipment_id: "",
  damaged: String(InboundDamagedStatus.Intact),
  __show: {
    status: "",
    damaged: "",
  },
  upload_images: [],
  relation_shipment: {
    id: 0,
    created_at: "",
    updated_at: "",
    deleted_at: "",
    created_uid: 0,
    updated_uid: 0,
    deleted_uid: 0,
    shipment_id: "",
    status: "",
    shipment_type: "",
    fulfillment_stage: 1,
    service_provider: "",
    pick_up_by: "",
    quantity: 0,
    tracking_no: "",
    delivery_from: 0,
    destination: "",
    actual_time_of_departure: "",
    estimated_time_of_departure: "",
    actual_time_of_arrival: "",
    estimated_time_of_arrival: "",
    packages: 1,
    file_id: 0,
    consigner: "",
    consigner_zip_code: "",
    note: "",
    merchant_id: 0,
  },
};

export interface InboundInfo {
  id: 0;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_uid: number;
  updated_uid: number;
  deleted_uid: number;
  status: number;
  batch_number: string;
  shipment_id: string;
  damaged: string;
  __show: InboundInfoShow;
  upload_images: InboundInfoUploadImage[];
  relation_shipment: InboundInfoRelationShipment;
}

interface InboundInfoShow {
  status: string;
  damaged: string;
}

interface InboundInfoUploadImage {
  id: number;
  size: string;
  name: string;
  url: string;
}

interface InboundInfoRelationShipment {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_uid: number;
  updated_uid: number;
  deleted_uid: number;
  shipment_id: string;
  status: string;
  shipment_type: string;
  fulfillment_stage: number;
  service_provider: string;
  pick_up_by: string;
  quantity: number;
  tracking_no: string;
  delivery_from: number;
  destination: string;
  actual_time_of_departure: string;
  estimated_time_of_departure: string;
  actual_time_of_arrival: string;
  estimated_time_of_arrival: string;
  packages: number;
  file_id: number;
  consigner: string;
  consigner_zip_code: string;
  note: string;
  merchant_id: NumberOrString;
}
