/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-13 13:13:05
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-01-12 19:25:04
 */
import { BadgeStyle } from "../type/common";
import { InboundStatus } from "../type/inbound";

/**
 * @description: 获取订单状态（新）
 * @return {*}
 */
export const getInboundStatusMap = (status: number): BadgeStyle | undefined => {
  const result = new Map([
    [InboundStatus.Incoming, BadgeStyle.LightWarning],
    [InboundStatus.Received, BadgeStyle.LightSuccess],
    [InboundStatus.Matching, BadgeStyle.LightInfo],
    [InboundStatus.Matched, BadgeStyle.LightPrimary],
    [InboundStatus.Mismatched, BadgeStyle.LightPrimary],
    [InboundStatus.Rejected, BadgeStyle.LightDark],
  ]);
  return result.get(status);
};
