
import { defineComponent, ref, onMounted, onActivated, onUpdated } from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import InboundReceivingPackageModal from "@/views/warehouse-management/inbound/ReceivingPackage.vue";
import InboundItemTable from "@/components/table/InboundItemTable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiInbound } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  FilterOption,
  MerchantOption,
  SortOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import { StockInTable } from "@/core/directive/interface/stockIn";

import store from "@/store";
import {
  CommonArrayToString,
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  formatDateTime,
  getDiffDays,
  getLateDays,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { getInboundStatusMap } from "@/core/directive/function/inbodund";
import EditModal from "../inbound/EditModal.vue";
import ImportFileModal from "@/components/upload/ImportModal.vue";
import {
  InboundDamagedStatus,
  InboundStatus,
} from "@/core/directive/type/inbound";
import {
  getUserWarhouseDescription,
  getUserWarhouseName,
} from "@/core/directive/function/user";
import { Mutations } from "@/store/enums/StoreEnums";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "warehouse-management-inbound",
  components: {
    MBDatatablePlus,
    InboundItemTable,
    InboundReceivingPackageModal,
    EditModal,
    ImportFileModal,
    PermissionCommon,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const receivingPackageRef = ref();
    const editRef = ref();
    const itemId = ref(0);

    const loading = ref(true);
    const disabledExportPackageMismatchedReports = ref(false);
    const tableData = ref<Array<StockInTable>>([]);
    const checkedCompanys = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("inbound.inboundNo"),
        key: "batch_number",
        sortable: false,
      },
      {
        name: t("inbound.chk"),
        key: "chk",
        sortable: false,
      },
      {
        name: t("inbound.source"),
        key: "source",
        sortable: false,
      },
      {
        name: t("inbound.tracking"),
        key: "tracking",
        sortable: false,
      },
      {
        name: t("inbound.scheduledDate"),
        key: "scheduled_date",
        sortable: false,
      },
      {
        name: t("inbound.receivedDate"),
        key: "received_date",
        sortable: false,
      },
      {
        name: t("order.qty"),
        key: "quantity",
        align: "right",
        sortable: false,
      },
      {
        name: t("inbound.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      uploadUrl: ApiInbound.importPackageReportsStockBatch(),
      received_date: "",
      relation_id: "",
      merchant: [] as MerchantOption[],
      merchantLoading: false,
      warehouse_inbound_status: [],
      status: [] as TaggingItem[],
      defaultWarehouse: {
        address: "",
        country: "",
        name: "",
      },
    });

    const init = () => {
      setModuleBCN(t, route);
      getInboundList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
      if (options.value.status.length === 0) {
        getTaggingData();
      }
      if (options.value.merchant.length === 0) {
        getMerchantSourceData("");
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["warehouse_inbound_status", "inbound_damaged_status"],
      });
      if (data.code == 0) {
        options.value.status = data.data.warehouse_inbound_status.items;
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const filterChange = () => {
      handleFilter(options.value);
      updateList();
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    const fileExportPackageMismatchedReports = () => {
      if (disabledExportPackageMismatchedReports.value) return;
      disabledExportPackageMismatchedReports.value = true;
      ApiInbound.exportPackageMismatchedReportsStockBatch({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportPackageMismatchedReports.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportPackageMismatchedReports.value = false;
          console.log(error);
        });
    };

    const getInboundList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiInbound.getInboundList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            tableData.value.forEach((item: any) => {
              item.downloadDisabled = false;
            });
            total.value = data.data.total;
            options.value.defaultWarehouse = data.data.default_warehouse;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getInboundList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getInboundList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getInboundList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const editItem = (id) => {
      itemId.value = id;
      editRef.value?.modalShow();
    };

    const handleResetItem = () => {
      itemId.value = 0;
    };

    const showReceivingPackage = () => {
      receivingPackageRef.value?.modalShow();
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != null) {
          if (val[item].length != 0) {
            if (item == "received_date") {
              filterArr.push({
                field: "received_date",
                value: commonChangeFilterAddDate(val[item][0], 1),
                condition: "gteq",
              });
              filterArr.push({
                field: "received_date",
                value: commonChangeFilterAddDate(val[item][1]),
                condition: "lteq",
              });
            } else if (item == "relation_id") {
              filterArr.push({
                field: "from_location.relation_id",
                value: val[item],
                condition: "eq",
              });
            } else if (item == "warehouse_inbound_status") {
              filterArr.push({
                field: "status",
                value: val[item],
                condition: "in",
              });
            }
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
    };

    const handleFilterReset = () => {
      resetFilter();
      getInboundList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getInboundList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const fileExport = (id, item) => {
      item.downloadDisabled = true;
      // ApiStock.print({
      //   id: id,
      //   download_type: "stream",
      // })
      //   .then((data) => {
      //     console.log(data.headers["content-disposition"]);
      //     item.downloadDisabled = false;
      //     const disposition = data.headers["content-disposition"];
      //     fileDownload(data.data, disposition.split("filename=")[1]);
      //   })
      //   .catch((error) => {
      //     item.downloadDisabled = false;
      //     console.log(error);
      //   });
    };

    return {
      t,
      getDiffDays,
      getLateDays,
      formatDate,
      formatDateTime,
      CommonArrayToString,
      InboundStatus,
      InboundDamagedStatus,
      getUserWarhouseName,
      getUserWarhouseDescription,
      receivingPackageRef,
      editRef,
      itemId,
      loading,
      tableHeader,
      tableData,
      options,
      currentPage,
      total,
      pageSize,
      search,
      checkedCompanys,
      editItem,
      handleResetItem,
      showReceivingPackage,
      getInboundList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      getInboundStatusMap,
      fileExport,
      searchMerchantSourceItems,
      filterChange,
      disabledExportPackageMismatchedReports,
      fileExportPackageMismatchedReports,
    };
  },
});
