
import JwtService from "@/core/services/JwtService";
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import Dropzone from "dropzone";
import mixin from "@/mixins";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { checkPermissionFlag } from "@/directives/permission";

export default defineComponent({
  name: "kt-upload-dropzone",
  props: {
    ktDropzone: { type: String, required: true },
    uploadUrl: { type: String, required: true },
    previewsContainer: { type: String, required: true },
    previewTemplate: { type: String, required: true },
    uploadMultiple: { type: Boolean, default: false },
    parallelUploads: { type: Number, required: false, default: 2 },
    maxFiles: { type: Number, required: false, default: 1 },
    countMaxFiles: { type: Number, required: false, default: 1 },
    maxFilesize: { type: Number, required: false, default: 5 },
    paramName: { type: String, required: false, default: "file" },
    params: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    acceptedFiles: { type: String, required: false, default: null },
  },
  emits: ["remove-file", "reset-form", "refuse", "cancel", "upload-status"],
  components: {},
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    let myDropzone = ref();

    const filesLister = ref<any[]>([]);
    const files = ref<any[]>([]);

    const updateFileList = (fileList) => {
      // //Add existing files into dropzone
      fileList.map((item) => {
        myDropzone.value?.emit("addedfile", item);
        myDropzone.value?.emit("complete", item);
        myDropzone.value?.emit("thumbnail", item, item.imageUrl);
        // myDropzone.value.displayExistingFile(
        //   item,
        //   item.imageUrl,
        //   () => {
        //     console.log("success");
        //   },
        //   true
        // );
      });
    };

    const setParams = (params) => {
      myDropzone.value.options.params = params;
    };

    const reset = () => {
      var items = document.querySelectorAll(".dz-details");
      items.forEach((item, index) => {
        if (index !== 0) {
          item.remove();
        }
      });
      // 调用插件方法不会生效（本地生效的情况staging环境也不会生效）
      // myDropzone.value?.removeAllFiles();
      // myDropzone.value?.emit("reset");
    };

    const showFilesTip = () => {
      Swal.fire({
        title: "",
        text: t("common.uploadFiles", [props.maxFiles]),
        icon: "error",
      });
    };

    const deShowFilesTip = _.debounce(showFilesTip, 50);

    const init = (params, fileList) => {
      const auto = checkPermissionFlag({ auth: ["update"] });
      var drop = document.getElementById(props.previewTemplate)?.innerHTML;
      myDropzone.value = new Dropzone("#" + props.ktDropzone, {
        url: props.uploadUrl,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
          "X-LANG": JwtService.getLang(),
        },
        params: props.params,
        maxFiles: props.countMaxFiles,
        maxFilesize: props.maxFilesize, // MB
        uploadMultiple: props.uploadMultiple,
        parallelUploads: props.parallelUploads,
        acceptedFiles: props.acceptedFiles,
        paramName: props.paramName,
        // maxThumbnailFilesize: 5,
        autoProcessQueue: auto,
        previewTemplate: drop,
        previewsContainer: "#" + props.previewsContainer,
        init: function () {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          if (auto) {
            this.on("maxfilesexceeded", function (file) {
              deShowFilesTip();
              return;
            });
            this.on("addedfile", (file) => {
              if (!file.id) {
                emit("upload-status", true);
              }
              let imageBox =
                file.previewElement.children[0].children[0].children[0]
                  .children[0];
              filesLister.value.push(imageBox);
              files.value.push(file);
              imageBox.addEventListener("click", function (e) {
                currentInstance.proxy.emitter.emit("preview-images", {
                  sources: [file.imageUrl],
                  sourcesIndex: 0,
                  sourcesSlide: 1,
                });
              });
            });
            this.on("successmultiple", (files, data, e) => {
              emit("upload-status", false);
              if (data.code === 0) {
                data.data.data.map((item, index) => {
                  files[index].id = item.file_upload_record_id;
                  files[index].imageUrl = item.url;
                });
              } else {
                showServerErrorMsg(data);
              }
            });
            this.on("removedfile", (file) => {
              let count = 0;
              files.value.map((item, index) => {
                if (item.imageUrl === file.imageUrl) {
                  filesLister.value[index].removeEventListener(
                    "click",
                    function (e) {
                      // console.log(e);
                    }
                  );
                  count = index;
                }
              });
              files.value.splice(count, 1);
              filesLister.value.splice(count, 1);
              emit("remove-file", file.id);
            });
          }
        },
      });
      setParams(params);
      updateFileList(fileList);

      // myDropzone.value?.on("addedfile", (file) => {
      //   if (!file.id) {
      //     emit("upload-status", true);
      //   }
      //   let imageBox =
      //     file.previewElement.children[0].children[0].children[0].children[0];
      //   filesLister.value.push(imageBox);
      //   files.value.push(file);
      //   imageBox.addEventListener("click", function (e) {
      //     currentInstance.proxy.emitter.emit("preview-images", {
      //       sources: [file.imageUrl],
      //       sourcesIndex: 0,
      //       sourcesSlide: 1,
      //     });
      //   });
      // });

      // myDropzone.value?.on("successmultiple", (files, data, e) => {
      //   emit("upload-status", false);
      //   if (data.code === 0) {
      //     data.data.data.map((item, index) => {
      //       files[index].id = item.file_upload_record_id;
      //       files[index].imageUrl = item.url;
      //     });
      //   } else {
      //     showServerErrorMsg(data);
      //   }
      // });
      // myDropzone.value.on("removedfile", (file) => {
      //   let count = 0;
      //   files.value.map((item, index) => {
      //     if (item.imageUrl === file.imageUrl) {
      //       filesLister.value[index].removeEventListener("click", function (e) {
      //         // console.log(e);
      //       });
      //       count = index;
      //     }
      //   });
      //   files.value.splice(count, 1);
      //   filesLister.value.splice(count, 1);
      //   emit("remove-file", file.id);
      // });
    };

    onMounted(() => {
      // init();
    });

    return {
      t,
      updateFileList,
      init,
      setParams,
      reset,
    };
  },
});
