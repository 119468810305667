
import { defineComponent, ref, onMounted, nextTick, computed } from "vue";
import Dropzone from "@/components/upload/Dropzone.vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  commonChangeDefaultDate,
  showModal,
} from "@/core/directive/function/common";
import _ from "lodash";
import { ApiBase, ApiInbound, ApiLogisticsServiceProvider } from "@/core/api";
import { inboundInfoData } from "@/core/directive/interface/inbound";
import { getInboundStatusMap } from "@/core/directive/function/inbodund";
import { MerchantOption, TaggingItem } from "@/core/directive/interface/common";
import {
  InboundDamagedStatus,
  InboundStatus,
} from "@/core/directive/type/inbound";
import { NumberOrString } from "@/core/directive/type/common";

export default defineComponent({
  name: "inbound-edit-modal",
  components: { Dropzone },
  props: {
    itemId: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update-list", "reset-form", "show-receiving-package"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showServerErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const uploadDropzoneRef = ref();
    const formRef = ref<null | HTMLFormElement>(null);
    const InboundEditModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const uploadUrl = ApiInbound.uploadInboundItemImages();

    const formData = ref(Object.assign({}, inboundInfoData));

    const options = ref({
      serviceProvider: [] as TaggingItem[],
      inboundDamagedStatus: [] as TaggingItem[],
      merchantLoading: false,
      merchant: [] as MerchantOption[],
      uploading: false,
      isCanReject: false,
      maxFiles: 200,
    });

    const rules = ref({
      "relation_shipment.tracking_no": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "relation_shipment.service_provider": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "relation_shipment.consigner_zip_code": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "relation_shipment.packages": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      damaged: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const noteErrorMsg = ref("");

    const disabledShippingDate = (time) => {
      return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的
    };

    const changeDamaged = (value: NumberOrString) => {
      formData.value.damaged = value as string;
    };

    const matched = async () => {
      loading.value = true;
      const { data } = await ApiInbound.inboundMatched({
        id: props.itemId,
      });
      loading.value = false;
      if (data.code === 0) {
        updateList();
      }
    };

    const reject = async () => {
      if (!formData.value.relation_shipment.note) {
        noteErrorMsg.value = t("common.isRequired");
        showValidateErrorMsg();
        return false;
      } else if (formData.value.relation_shipment.note.trim() === "") {
        noteErrorMsg.value = t("common.isRequired");
        showValidateErrorMsg();
        return false;
      } else {
        noteErrorMsg.value = "";
      }
      loading.value = true;
      const { data } =
        await ApiInbound.updateInboundWarehouseStockInBatchStatus({
          id: props.itemId,
          note: formData.value.relation_shipment.note,
        });
      loading.value = false;
      if (data.code === 0) {
        updateList();
      }
    };

    const getMaxFiles = computed(() => {
      return options.value.maxFiles - formData.value.upload_images.length > 0
        ? options.value.maxFiles - formData.value.upload_images.length
        : 0;
    });

    const getShowInfo = async () => {
      loading.value = true;
      const { data } = await ApiInbound.getInboundInfo({
        id: props.itemId,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        if (!data.data.damaged) {
          formData.value.damaged = String(InboundDamagedStatus.Intact);
          options.value.isCanReject = false;
        } else {
          if (data.data.damaged !== String(InboundDamagedStatus.Intact)) {
            options.value.isCanReject = true;
          } else {
            options.value.isCanReject = false;
          }
        }
        let mockList: any[] = [];
        formData.value.upload_images.map((item) => {
          mockList.push({
            id: item.id,
            name: item.name,
            size: item.size,
            imageUrl: item.url,
          });
        });
        uploadDropzoneRef.value?.init(
          {
            id: formData.value.id,
          },
          mockList
        );
        // uploadDropzoneRef.value?.setParams({
        //   id: formData.value.id,
        // });
        // uploadDropzoneRef.value?.updateFileList(mockList);
      } else {
        showServerErrorMsg(data);
      }
    };

    const uploadStatus = (status: boolean) => {
      options.value.uploading = status;
      // loading.value = status;
    };

    const removeFile = async (fileId) => {
      const { data } = await ApiInbound.deleteInboundItemImage({
        id: props.itemId,
        file_upload_record_id: fileId,
      });
      loading.value = false;
      if (data.code == 0) {
        console.log("success");
      } else {
        console.log("file");
        // showServerErrorMsg(data);
      }
    };

    const getLogisticsServiceProviderData = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.getLogisticsServiceProviderData({
          max_item: "all",
        });
      if (data.code == 0) {
        let shipmentProviderOptionsData = data.data;
        let pickUp = {
          label: t("shipments.pickUpBy"),
          value: -1,
        };
        shipmentProviderOptionsData.push(pickUp);

        options.value.serviceProvider = shipmentProviderOptionsData;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["inbound_damaged_status"],
      });
      if (data.code == 0) {
        options.value.inboundDamagedStatus =
          data.data.inbound_damaged_status.items;
      }
    };

    const querySearchAsync = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      const params = queryString
        ? {
            name: queryString,
          }
        : {
            zip_code: formData.value.relation_shipment.consigner_zip_code,
          };
      const { data } = await ApiBase.getMerchantWarehouseSourceData(params);
      if (data.code == 0) {
        cb([...data.data.merchant, ...data.data.warehouse]);
      }
    };

    const getZipCodeSourceData = async (value) => {
      // options.value.merchantLoading = true;
      // const params = {
      //   zip_code: value,
      // };
      // const { data } = await ApiBase.getMerchantWarehouseSourceData(params);
      // // options.value.merchantLoading = false;
      // if (data.code == 0) {
      //   options.value.merchant = data.data;
      // }
      querySearchAsync("", value);
    };

    const debounceMerchantWarehouseSourceSearch = _.debounce(
      getZipCodeSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantWarehouseSourceSearch(query);
    };

    const submitHide = () => {
      submit(() => {
        console.log("Hide");
      });
    };

    const submitShowNew = () => {
      submit(() => {
        emit("show-receiving-package");
      });
    };

    const submit = (callback) => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          let params = {
            id: formData.value.id,
            shipment: {
              service_provider:
                formData.value.relation_shipment.service_provider,
              actual_time_of_departure:
                formData.value.relation_shipment.actual_time_of_departure,
              consigner_zip_code:
                formData.value.relation_shipment.consigner_zip_code,
              packages: formData.value.relation_shipment.packages,
              note: formData.value.relation_shipment.note,
              consigner: formData.value.relation_shipment.consigner,
              tracking_no: formData.value.relation_shipment.tracking_no,
            },
            merchant_id: formData.value.relation_shipment.merchant_id,
            warehouse_stock_batch_in: {
              damaged: formData.value.damaged,
            },
          };
          const { data } = await ApiInbound.updateInboundInfo(params);
          loading.value = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              updateList();
              callback();
            });
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      uploadDropzoneRef.value?.reset();
      emit("reset-form");
    };

    const updateList = () => {
      emit("update-list");
      modalHide();
    };

    const modalShow = () => {
      showModal(InboundEditModalRef.value);
    };

    const modalHide = () => {
      hideModal(InboundEditModalRef.value);
    };

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    onMounted(() => {
      modalShowListener(InboundEditModalRef.value, () => {
        nextTick(() => {
          getShowInfo();
        });
      });
      modalHideListener(InboundEditModalRef.value, () => {
        resetForm();
      });
      getLogisticsServiceProviderData();
      getTaggingData();
    });

    return {
      t,
      InboundStatus,
      InboundDamagedStatus,
      props,
      loading,
      uploadUrl,
      uploadDropzoneRef,
      formData,
      rules,
      formRef,
      InboundEditModalRef,
      options,
      noteErrorMsg,
      disabledShippingDate,
      changeDamaged,
      reject,
      matched,
      getMaxFiles,
      uploadStatus,
      removeFile,
      getInboundStatusMap,
      commonChangeDefaultDate,
      querySearchAsync,
      searchMerchantSourceItems,
      modalShow,
      modalHide,
      submitHide,
      submitShowNew,
      submit,
      resetForm,
      updateList,
    };
  },
});
