
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ApiInbound } from "@/core/api";
import CommonProduct from "./CommonProduct.vue";

export default defineComponent({
  name: "kt-inbound-item-table",
  props: {
    skuId: {
      type: Number,
      required: true,
      default: () => {
        return 0;
      },
    },
  },
  components: {
    CommonProduct,
  },
  setup(props) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const base = ref("");
    const order = ref("");
    const isRequest = ref<boolean>(false);

    const formData = ref({
      items: [] as any[],
    });

    const options = ref({});

    const getShowInfo = async () => {
      loading.value = true;
      const { data } = await ApiInbound.getInboundItem({
        id: props.skuId,
      });
      loading.value = false;
      isRequest.value = true;
      if (data.code == 0) {
        formData.value.items = data.data;
      }
    };

    onMounted(() => {
      getShowInfo();
    });

    return {
      t,
      props,
      loading,
      base,
      order,
      options,
      isRequest,
      formData,
    };
  },
});
