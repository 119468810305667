import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "fv-row" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "dz-message needsclick" }
const _hoisted_5 = { class: "svg-icon svg-icon-4x svg-icon-primary" }
const _hoisted_6 = { class: "ms-4" }
const _hoisted_7 = { class: "fs-5 fw-bold text-gray-900 mb-1" }
const _hoisted_8 = { class: "fs-7 fw-semibold text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "dropzone",
        id: _ctx.ktDropzone
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/files/fil009.svg" })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.t("common.uploadDrone")), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t("common.uploadFiles", [_ctx.maxFiles])), 1)
          ])
        ])
      ], 8, _hoisted_3)
    ])
  ]))
}